// Run function when the document or page has loaded
function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

// Run main actions
function run_main() {
  // hide loading modal and show all content once everything is loaded
  document.querySelector('#loader').style.display = 'none';
  document.querySelector('main').classList.remove('hidden');
  document.querySelector('#main-content').classList.remove('hidden');

  // collapses the sidebar
  let sidebar = document.querySelector('#sidebar');
  
  if (sidebar) {
    let sidebar_collapser = document.querySelector('#sidebar-collapser');
    let navbar_sidebar_collapser = document.querySelector('#navbar-sidebar-collapser');
    let floating_wizard = document.querySelector('#floating-wizard');
    
    // ensures that the floating wizard stays by the sidebar whether it's collapsed or not
    if (floating_wizard) {
      floating_wizard.style.left = `calc(${sidebar.offsetWidth}px - 70px)`;
    }
    
    navbar_sidebar_collapser.addEventListener('click', event => {
      toggle_sidebar(event.currentTarget, sidebar, floating_wizard);
    });
    
    sidebar_collapser.addEventListener('click', event => {
      toggle_sidebar(event.currentTarget, sidebar, floating_wizard);
    });
    
    // event listener for mini wizard
    let nav_wizard_links = document.querySelectorAll('.nav-wizard a');
    let nav_items = document.querySelectorAll('.nav-space .nav.nav-tabs > a.nav-link.nav-item');
    
    nav_wizard_links.forEach(nav_wizard_link => {
      nav_wizard_link.addEventListener('click', event => {
        let active_nav_wizard_link = document.querySelector('.nav-wizard a.active');
        let mini_wizard_content_links = document.querySelectorAll('.mini-wizard-content a.link');
        let target = event.currentTarget;
        let href = target.getAttribute('href');
        
        event.preventDefault();
        active_nav_wizard_link?.classList.add('completed');
        target.setAttribute('aria-selected', 'true');
        
        mini_wizard_content_links.forEach(mini_wizard_content_link => {
          let mini_wizard_content_link_href = mini_wizard_content_link.getAttribute('href');

          if (mini_wizard_content_link_href === href) {
            mini_wizard_content_link.classList.add('active');
            mini_wizard_content_link.setAttribute('aria-selected', 'true');
          } else {
            mini_wizard_content_link.classList.remove('active');
            mini_wizard_content_link.setAttribute('aria-selected', 'false');
          }
        });

        window.location.hash = href;
        set_anchor();
      });
    });

    nav_items.forEach(nav_item => {
      nav_item.addEventListener('click', () => {
        let active_nav_item = document.querySelector('.nav-space a.nav-link.nav-item.active');

        active_nav_item?.classList.toggle('active');
      });
    });
  }

  // event listener for navigation footer
  let arrow_buttons = document.querySelectorAll('a.back-arrow, a.next-arrow');

  arrow_buttons.forEach(arrow_button => {
    arrow_button.addEventListener('click', event => {
      let target = event.currentTarget;

      event.preventDefault();
      target.classList.remove('active');
      target.setAttribute('aria-selected', 'false');

      window.location.hash = target.getAttribute('href');
      set_anchor();
    });
  });

  // set anchor when document or page is loaded
  set_anchor();

  // ensures hyperlinks set in the eqa step instructions, and homepage panels opens in a new tab
  let hyperlinks = document.querySelectorAll('.eqa-step-instructions a, .trix-content a, .carousel-inner a');

  hyperlinks.forEach(hyperlink => {
    hyperlink.setAttribute('target', '_blank');
  });
}

// Toggle sidebar
function toggle_sidebar(target, sidebar, floating_wizard) {
  let floating_wizard_left = 0;

  sidebar.classList.toggle('collapsed');

  if (sidebar.classList.contains('collapsed')) {
    set_sidebar_state('collapsed');
  } else {
    floating_wizard_left = 'calc(150px - 70px)';
    set_sidebar_state('show');
  }

  if (floating_wizard) {
    floating_wizard.style.left = floating_wizard_left;
  }
}

// Sets the sidebar state by passing the state (i.e. show or collapsed ) to the application controller
window.set_sidebar_state = async state => {
  let data = { sidebar_state: state };

  await fetch('/sidebar_state', {
    method: 'POST',
    headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
    body: JSON.stringify(data)
  });
};

ready(run_main);